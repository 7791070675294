.anonymize-h1 {
    @apply text-black-900 text-[64px] font-medium leading-[96px];
}

.anonymize-h2 {
    @apply text-[36px] font-normal leading-[54px];
}

.anonymize-h3 {
    @apply text-[24px] font-normal leading-[36px];
}

.anonymize-h4 {
    @apply text-[14px] font-semibold leading-[21px];
}

.anonymize-h5 {
    @apply text-[16px] font-medium leading-[29px];
}

.anonymize-p1 {
    @apply text-[20px] font-normal leading-[30px];
}

.anonymize-p2 {
    @apply text-[16px] font-medium leading-[24px];
}

.anonymize-p3 {
    @apply text-[14px] font-medium leading-[21px];
}

.anonymize-p4 {
    @apply text-[14px] font-normal leading-[21px];
}

.anonymize-p5 {
    @apply text-[12px] font-normal leading-[18px];
}

@media (min-width: 768px) {
    .anonymize-h1-responsive {
        @apply anonymize-h1;
    }
}
@media (max-width: 768px) {
    .anonymize-h1-responsive {
        @apply anonymize-h2;
    }
}
