/*  Toc */
.gh-content-toc {
    position: relative;
    width: 100%;
}

.gh-toc > .toc-list {
    position: relative;
}

.toc-list-item > a {
    padding: 16px 8px;
    display: flex;
    width: 100%;
}

.toc-list > li:hover {
    background-color: #f2f7ff;
}

.toc-list {
    overflow: hidden;
    list-style: none;
    padding-left: 0;
}
@media (min-width: 768px) {
    .gh-sidebar {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        grid-column-start: auto;
        width: 250px;
    }
}
@media (max-width: 768px) {
    .gh-sidebar {
        display: none; /* Hide toc for small screen */
    }
}
.gh-toc {
    overflow: hidden;
}

.toc-list > .is-active-li {
    text-decoration: none;
    font-weight: 400;
    background-color: #d8e8ff;
}

.toc-list > .toc-list-item > .toc-link {
    text-decoration: none;
    font-weight: 400;
}

.toc-link::before {
    background: transparent;
    /* Defines TOC   accent color based on Accent color set in Ghost Admin */
}

.toc {
    position: sticky;
    top: 100px;
}
