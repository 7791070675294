@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
    :root {
        --color-brand: 17 24 39;
    }

    html,
    body {
        @apply bg-white font-inter antialiased;
    }

    .dynamic-html h6 {
        @apply font-medium uppercase;
    }

    .dynamic-html p {
        @apply mb-3;
    }

    [type='text'],
    [type='email'],
    [type='url'],
    [type='password'],
    [type='number'],
    [type='date'],
    [type='datetime-local'],
    [type='month'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='week'],
    [multiple],
    textarea,
    select {
        padding: 16.5px 14px;
        @apply bg-transparent;
    }

    [type='text']:focus,
    [type='email']:focus,
    [type='url']:focus,
    [type='password']:focus,
    [type='number']:focus,
    [type='date']:focus,
    [type='datetime-local']:focus,
    [type='month']:focus,
    [type='search']:focus,
    [type='tel']:focus,
    [type='time']:focus,
    [type='week']:focus,
    [multiple]:focus,
    textarea:focus,
    select:focus {
        @apply ring-0 ring-transparent;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@layer utilities {
    .text-case-inherit {
        text-transform: inherit;
    }

    .letter-space-inherit {
        letter-spacing: inherit;
    }

    .word-break-all {
        word-break: break-all;
    }

    .animation-delay-200 {
        animation-delay: 200ms;
    }

    .animation-delay-500 {
        animation-delay: 500ms;
    }

    .animation-delay-700 {
        animation-delay: 700ms;
    }

    .text-vertical {
        writing-mode: tb;
        text-orientation: upright;
    }
}

/* Hide spin button for input type number */
input.spin-button-hidden::-webkit-outer-spin-button,
input.spin-button-hidden::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
