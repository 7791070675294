@import '@app/assets/css/tailwind.css';

.small-button {
    @apply P2 h-[37px] rounded-[4px] bg-blue px-4 py-2 !text-white hover:bg-blue-6 hover:no-underline;
}

.large-button {
    @apply P1 h-[48px] rounded-[4px] bg-blue px-8 py-3 !text-white hover:bg-blue-6  hover:no-underline;
}

.tag {
    @apply P2 flex h-[26px] items-center justify-center;
}

.h1 {
    @apply font-baiJamjuree text-[64px] !font-medium leading-[96px] tracking-[-0.64px] text-black-1;
}

.h2 {
    @apply font-arial text-[48px] leading-[72px] text-black-1;
}

.h3 {
    @apply font-arial text-[40px] leading-[60px] text-black-1;
}

.h4 {
    @apply font-arial text-[36px] leading-[54px] text-black-1;
}

.h5 {
    @apply font-arial text-[20px] leading-[32px] text-black-1;
}

.P1 {
    @apply text-[16px] leading-[24px] text-black-2;
}

.P2 {
    @apply text-[14px] leading-[21px] text-black-2;
}

.microCopy {
    @apply text-[12px] leading-[18px] text-black-2;
}

@media (min-width: 1310px) {
    .h1-responsive {
        @apply h1;
    }
}
@media (max-width: 768px) {
    .h1-responsive {
        @apply h4 !font-baiJamjuree !font-medium !tracking-[-0.64px];
    }
}

@media (max-width: 1310px) {
    .h1-responsive {
        @apply h4 !font-baiJamjuree !font-medium !tracking-[-0.64px];
    }
}

@media (max-width: 768px) {
    .h2-responsive {
        @apply h5;
    }
}

@media (min-width: 768px) {
    .h2-responsive {
        @apply h2;
    }
}

@media (max-width: 768px) {
    .h3-responsive {
        @apply h4;
    }
}

@media (min-width: 768px) {
    .h3-responsive {
        @apply h3;
    }
}

@media (max-width: 768px) {
    .h4-responsive {
        @apply h5;
    }
}

@media (min-width: 768px) {
    .h4-responsive {
        @apply h4;
    }
}

/* A larger margin before/after HRs and blockquotes */
.gh-content > hr,
.gh-content > blockquote {
    position: relative;
    margin-top: max(4.8vmin, 32px);
}

.gh-content > hr + *,
.gh-content > blockquote + * {
    margin-top: max(4.8vmin, 32px) !important;
}

/* Now the content typography styles */
.gh-content a {
    word-break: break-word;
    text-decoration: underline;
}

.gh-content > figure {
    position: relative;
    max-height: 500px;
    width: 100%;
    margin: 80px 0px 0px 0px;
    background-color: #0099ff1a;
    overflow: hidden;
    object-fit: fill;
}

@media (min-width: 768px) {
    .gh-content > p {
        @apply P1 mt-[20px] text-black-2;
    }

    .gh-content > ol,
    .gh-content > ul {
        @apply P1 list-disc py-[14px] pl-[21px];
    }

    .gh-content > ul > li {
        margin: 8px 0px !important;
    }
}

@media (max-width: 768px) {
    .gh-content > p {
        @apply P1 mt-[10px];
    }

    .gh-content > ul > li {
        margin: 4px 0px !important;
    }

    .gh-content > ol,
    .gh-content > ul {
        list-style-type: disc;
        @apply P1 py-[10px] pl-[21px];
    }
}

.gh-canvas > * {
    grid-column: main-start / main-end;
}

/* [id] represents all headings h1-h6, reset all margins */
.gh-content > [id] {
    @apply h4-responsive -mt-[40px] pt-[80px];
}

.gh-content > [id]:not(.exclude) strong {
    @apply h4-responsive font-medium;
}
